import * as React from "react";

import {
  HomepageHero,
  Services,
  HomePageQuoteSection,
  HomePageTestimonial,
  GrowthInNumbers,
  HomePageCoreValues,
} from "templates/home";
import { Layout, OurMission } from "components";

const IndexPage = () => (
  <Layout
    title="Wetse - Empowering Professionals for Endless Possibilities"
    ignoreSiteName={true}
  >
    <HomepageHero />
    <OurMission />
    <HomePageCoreValues />
    <Services />
    <GrowthInNumbers />
    <HomePageTestimonial />
    <HomePageQuoteSection />
  </Layout>
);

export default IndexPage;
