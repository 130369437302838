import React from "react";
import ctl from "@netlify/classnames-template-literals";
import { Text, Br } from "components";
import WebinarIcon from "svgs/webinar.svg";
import PublicSpeakingIcon from "svgs/public-speaking.svg";
import TrainingIcon from "svgs/training.svg";
import ConsultationIcon from "svgs/consultation.svg";
import ResearchIcon from "svgs/research.svg";
import PodcastIcon from "svgs/podcast.svg";
import CoachingIcon from "svgs/coaching.svg";

const MobileServices = () => {
  return (
    <div className={sectionWrapStyle}>
      <IconAndText
        icon={<WebinarIcon />}
        topText="Webinar Hosting"
        bottomText="and Management"
      />

      <IconAndText
        icon={<PodcastIcon />}
        topText="Educational"
        bottomText="Podcast"
      />

      <IconAndText
        icon={<ResearchIcon />}
        topText="Research &"
        bottomText="Application"
      />

      <IconAndText
        icon={<CoachingIcon />}
        topText="Career Coaching"
        bottomText="& Mentoring "
      />

      <IconAndText
        icon={<PublicSpeakingIcon />}
        topText="Public"
        bottomText="Speaking"
      />

      <IconAndText
        icon={<TrainingIcon />}
        topText="Bespoke Trainings,"
        bottomText="Seminars & Conferences "
      />

      <IconAndText
        icon={<ConsultationIcon />}
        topText="Professional"
        bottomText="Consultation"
      />
    </div>
  );
};

const IconAndText = ({ icon, topText, bottomText, left }) => (
  <div className={servicesWrapStyle}>
    {icon}
    <span className={left && "text-right"}>
      <Text
        variant="h5"
        color="alternative-700"
        value={
          <>
            {" "}
            {topText} <Br /> {bottomText}{" "}
          </>
        }
      />
    </span>
  </div>
);

const sectionWrapStyle = ctl(`
  grid 
  md:grid-cols-2 
  grid-cols-1 
  xl:hidden
  mt-8
`);
const servicesWrapStyle = ctl(`
  flex
  items-center
  gap-5 md:gap-3
  lg:mt-5
  md:mt-4
  mobile-service-wrap
`);

export { MobileServices };
