import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import ctl from "@netlify/classnames-template-literals";

const baseStyle = ctl(`
xl:!w-[712px]
xl:!h-[681px]
md:w-[412px]
w-[260px]

`);

const homePageTestimonialsData = [
  {
    testimonial:
      "Thank you for a great course! Great presentation style with lots of opportunities to ask many questions and discussions using real-life examples – which all made for a really enjoyable and informative course. ",
    name: "- Michael Ofori Andoh (Ghana)",
    role: "Project Officer, Christian Health Association of Ghana",
    image: (
      <StaticImage
        src="../assets/images/jpegs/home/testimonial-1.png"
        className={baseStyle}
        alt="Successful Completion of SHERM Foundation Course"
      />
    ),
  },
  {
    testimonial:
      "'The course was very refreshing, with excellent content and instruction based on real-world scenarios.' With the exception of ceiling fans, I found the interactions to be no different from classroom learning’ ",
    name: "- Nketia, Eric Sarfo (Ghana)",
    role: "QHSE Compliance Officer.  Deeprock Drilling Services",
    image: (
      <StaticImage
        src="../assets/images/jpegs/home/testimonial-2.png"
        alt="Assessment of SHERM Foundation Course"
        className={baseStyle}
      />
    ),
  },
  {
    testimonial:
      "My experience at SHERM Foundation was great and memorable. The facilitators and mentors at SHERM are the best. They have enhanced my professional career.",
    name: "- Elvis Duncan Bonnah (Ghana)",
    role: "Project Officer, Christian Health Association of Ghana",
    image: (
      <StaticImage
        src="../assets/images/jpegs/home/testimonial-3.png"
        alt="Opening of SHERM Foundation Course in Accra"
        className={baseStyle}
      />
    ),
  },
];

export { homePageTestimonialsData };
