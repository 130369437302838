import React from "react";

import { Quote } from "components";

const HomePageQuoteSection = () => {
  return (
    <Quote
      quote="Today, technological progress and intense competitive pressures bring rapid change in working conditions, work processes and organization. Legislation is essential but insufficient on its own to address these changes or to keep pace with new hazards and risks. Organizations must also be able to tackle occupational safety and health challenges continuously and to build effective responses into dynamic management strategies."
      author="- Juan Somavia"
      role="Director General ILO (1999 - 2012), Geneva"
    />
  );
};

export { HomePageQuoteSection };
