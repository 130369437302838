import React from "react";
import { homePageTestimonialsData } from "config/slider";
import { Container, Text, TestiomonialSlider } from "components";

const HomePageTestimonial = () => {
  return (
    <>
      <Container>
        <Text
          variant="h3"
          value="What Our Clients Say About Us"
          color="secondary-900"
        />
      </Container>

      <TestiomonialSlider testimonials={homePageTestimonialsData} />
    </>
  );
};

export { HomePageTestimonial };
