import React from "react";
import ctl from "@netlify/classnames-template-literals";
import { Text } from "components";
import { StaticImage } from "gatsby-plugin-image";
import ReliabilityIcon from "svgs/reliability .svg";
import TrustIcon from "svgs/trust.svg";
import StrategicIcon from "svgs/strategic.svg";
import YouthIcon from "svgs/youthfulness.svg";
import ValueIcon from "svgs/value.svg";
import ExcellenceIcon from "svgs/excellence.svg";

const DesktopCoreValues = () => {
  return (
    <div className={sectionWrapStyle}>
      <div className="left-value-wrap">
        <IconAndText
          left
          icon={<StrategicIcon />}
          text="Strategic"
          extraClass="mt-[108px]"
        />

        <IconAndText
          left
          icon={<ReliabilityIcon />}
          text="Resilient"
          extraClass="mt-[140px]"
        />

        <IconAndText
          left
          icon={<ValueIcon />}
          text={<>Value-adding</>}
          extraClass="strategy"
        />
      </div>

      <StaticImage
        src="../../assets/images/jpegs/core-values.png"
        className="w-[720px]"
        alt="Wetse core vaalues image"
      />

      <div className="right-value-wrap">
        <IconAndText
          icon={<TrustIcon />}
          text="Trustworthy"
          extraClass="mt-[230px]"
        />

        <IconAndText
          icon={<YouthIcon />}
          text="Youthful"
          extraClass="mt-[150px]"
        />

        <IconAndText
          icon={<ExcellenceIcon />}
          text="Excellence"
          extraClass="mt-[140px]"
        />
      </div>
    </div>
  );
};

const IconAndText = ({ icon, text, left, extraClass }) => (
  <div
    className={`${valueWrapStyle} ${
      left ? leftValueStyle : rightValueStyle
    } ${extraClass}`}
  >
    <Text value={text} variant="h5" color="alternative-700" />
    <span className={!left && "order-first"}>{icon}</span>
  </div>
);

const sectionWrapStyle = ctl(`
  hidden 
  xl:flex 
  justify-center
`);
const valueWrapStyle = ctl(`
  flex
  items-center
  gap-5
`);
const leftValueStyle = ctl(`
  justify-end
  2xl:-mr-10
  -mr-7
`);
const rightValueStyle = ctl(`
  -ml-4
`);

export { DesktopCoreValues };
