import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import ctl from "@netlify/classnames-template-literals";

import { Button, Text, Container } from "components";

const HomepageHero = () => (
  <section>
    <div className="bg-primary relative h-screen md:max-h-[780px] overflow-hidden pt-[96px] md:pt-0  md:flex items-center">
      <Container>
        <div className="relative z-10">
          <Text
            variant="h1"
            value="Empowering Professionals for Endless Possibilities"
            color="primary-100"
            className={heroTextStyle}
          />

          <Text
            variant="p18"
            color="primary-100"
            value="Together, we can enhance Health, Safety, Environment, Risk Management and Sustainability practices in the workplace."
            className={heroParagraphStyle}
          />

          <div className={buttonWrapStyle}>
            <Button size="medium" variant="tertiary" to="/register">
              Get Started
            </Button>
          </div>
        </div>

        <StaticImage
          width={700}
          src="../../../assets/images/jpegs/home/road.webp"
          alt="homepage header image"
          className={heroImageStyle}
        />
      </Container>
    </div>
    <StaticImage
      src="../../../assets/images/jpegs/header-pattern.png"
      className="w-full"
    />
  </section>
);

const heroTextStyle = ctl(`
  xl:max-w-[612px] 
  md:max-w-[650px] 
  sm:max-w-[300px]
  max-w-[343px]
  lg:mb-8 mb-4
  pt-12
  lg:pt-0
 
`);
const heroParagraphStyle = ctl(`
  lg:max-w-[504px] 
  sm:max-w-[360px]
  max-w-[300px]
  lg:mb-14 
  mb-[38px]
`);

const heroImageStyle = ctl(`
!absolute
2xl:right-0
xl:right-[-10%]
md:right-[-20%]
right-[-10%]
2xl:w-auto
md:w-[55%]
sm:w-[70%]
w-[90%]
2xl:bottom-0
xl:bottom-[-10%]
md:bottom-[-5%]
bottom-[-15%]
z-0
home-hero-image
pointer-events-none
`);

const buttonWrapStyle = ctl(`
  mb-7 
  lg:mb-0 
  max-w-[236px] 
  lg:max-w-auto 
`);

export { HomepageHero };
