import React from "react";
import ctl from "@netlify/classnames-template-literals";
import { Container } from "components";
import { Text } from "components/text";
import { growthData } from "config";

const GrowthInNumbers = () => {
  return (
    <Container>
      <Text
        variant="h3"
        value="Our Growth in Numbers"
        className={headingStyle}
      />

      <ul className={listWrapStyle}>
        {growthData.map((item, index) => (
          <Counter key={`growth_in_${item.number}_${index}`} {...item} />
        ))}
      </ul>
    </Container>
  );
};

const Counter = ({ number, text }) => {
  return (
    <li className={listItemStyle}>
      <Text variant="h2" value={number} color="primary-800" />
      <Text variant="p16" value={text} color="secondary-800" />
    </li>
  );
};

const headingStyle = ctl(`
  md:pt-[275px]
  pt-[74px]
  text-center
`);
const listWrapStyle = ctl(`
  md:flex
  justify-between
  md:pt-[53px]
  md:pb-[186px] py-9
`);
const listItemStyle = ctl(`
  text-center
  mb-8 md:mb-0
`);

export { GrowthInNumbers };
