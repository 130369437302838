import React from "react";
import ctl from "@netlify/classnames-template-literals";
import { Container, ReadMore, Text } from "components";
import { DesktopServices } from "./desktop-services";

import { MobileServices } from "./mobile-services";

const Services = () => {
  return (
    <section className={sectionWrapStyle}>
      <Container>
        <div className="md:max-w-[600px] sm:max-w-[520px]">
          <Text
            variant="h3"
            value="We provide professionals with resources to become resilient in their careers and workplace operations"
            color="secondary-100"
          />
          <span className={servicesParagraphStyle}>
            <ReadMore
              text="Explore our suite of services"
              to="/our-services"
              color="secondary-100"
            />
          </span>
        </div>

        <DesktopServices />
        <MobileServices />
      </Container>
    </section>
  );
};

const sectionWrapStyle = ctl(`
  bg-primary
  xl:pb-[155px]
  pb-[119px]
  pt-[40px] xl:pt-0
`);
const servicesParagraphStyle = ctl(`
  flex 
  items-center 
  gap-4 
  mt-4
`);

export { Services };
