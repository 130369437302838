import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import ctl from "@netlify/classnames-template-literals";
import { Text, Br } from "components";
import WebinarIcon from "svgs/webinar.svg";
import PublicSpeakingIcon from "svgs/public-speaking.svg";
import TrainingIcon from "svgs/training.svg";
import ConsultationIcon from "svgs/consultation.svg";
import ResearchIcon from "svgs/research.svg";
import PodcastIcon from "svgs/podcast.svg";
import CoachingIcon from "svgs/coaching.svg";

const DesktopServices = () => {
  return (
    <div className="hidden xl:block mt-20">
      <div className="left-service-wrap">
        <IconAndText
          left
          icon={<WebinarIcon />}
          topText="Webinar Hosting"
          bottomText="and Management"
          extraClass="-mt-10 ml-[210px] 2xl:ml-80 "
        />

        <IconAndText
          left
          icon={<PodcastIcon />}
          topText="Educational"
          bottomText="Podcast"
          extraClass="mt-[165px] ml-5 2xl:ml-28"
        />

        <IconAndText
          left
          icon={<ResearchIcon />}
          topText="Research &"
          bottomText="Application"
          extraClass="mt-[440px] ml-6 2xl:ml-28"
        />

        <IconAndText
          left
          icon={<CoachingIcon />}
          topText="Career Coaching"
          bottomText="& Mentoring "
          extraClass="mt-[670px] ml-[210px] 2xl:ml-80 "
        />
      </div>

      <div className="flex justify-center">
        <StaticImage
          src="../../../assets/images/jpegs/services.png"
          className="w-[832px] "
          alt="Wetse services image"
        />
      </div>

      <div className="flex justify-end right-service-wrap">
        <IconAndText
          icon={<PublicSpeakingIcon />}
          topText="Public"
          bottomText="Speaking"
          extraClass="-mt-[800px] mr-72 2xl:mr-[400px]"
        />

        <IconAndText
          icon={<TrainingIcon />}
          topText="Bespoke Trainings,"
          bottomText="Seminars & Conferences "
          extraClass="-mt-[610px] -mr-[100px] 2xl:-mr-11 bespoke"
        />

        <IconAndText
          icon={<ConsultationIcon />}
          topText="Professional"
          bottomText="Consultation"
          extraClass="-mt-[330px] 2xl:mr-24"
        />
      </div>
    </div>
  );
};

const IconAndText = ({ icon, topText, bottomText, left, extraClass }) => (
  <div className={`${valueWrapStyle} ${left && leftValueStyle} ${extraClass}`}>
    {icon}
    <span className={left && "text-right"}>
      <Text
        variant="h5"
        color="alternative-700"
        value={
          <>
            {" "}
            {topText} <Br /> {bottomText}{" "}
          </>
        }
      />
    </span>
  </div>
);

const valueWrapStyle = ctl(`
  flex 
  flex-col
  gap-5
  z-10
  absolute
`);
const leftValueStyle = ctl(`
  items-end
`);

export { DesktopServices };
